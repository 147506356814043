// Global PrimeNG custom styles
@import '_mixins.scss';

.p-inputswitch {
  .p-inputswitch-slider {
    @apply rounded-[30px];
    &:before {
      @apply rounded-[50%];
    }
  }
}

lib-ngx-photo-editor {
  .ngx-pe-dialog-header {
    @apply bg-palette-base-white text-palette-base-black;
  }

  .ngx-pe-tool-bar {
    @apply bg-palette-base-white border-b-2 py-3;
    button {
      &:hover {
        @apply bg-transparent;
      }
      svg {
        @apply fill-palette-gray-600;
      }
    }
  }

  .ngx-pe-dialog-footer {
    @apply bg-palette-base-white;
    .ngx-pe-btn {
      @apply text-base py-2 rounded-lg;
      &.ngx-pe-btn-outline-light {
        @apply border border-palette-gray-200 text-palette-gray-700;
      }
    }
  }
}

.btn-primary-500-xs {
  @apply border-branding-primary-500 bg-branding-primary-500 text-xs text-palette-base-white;
}

.btn-primary-600-xs {
  @apply border-branding-primary-600 bg-branding-primary-600 text-xs text-palette-base-white;
}

.btn-primary-700-xs {
  @apply border-branding-primary-700 bg-branding-primary-700 text-xs text-palette-base-white;
}

.btn-header-tab {
  @apply py-2 px-4 rounded-lg text-base font-semibold;
  &.selected {
    @apply text-palette-blue-700 bg-branding-primary-50;
  }
  &.unselected {
    @apply text-palette-gray-500 bg-transparent;
  }
}

.badges-border {
  &.p-button.p-button-outlined {
    @apply text-transparent p-[1px];
  }
  border-image-slice: 1 !important;
  @apply font-medium border border-solid;
  .badges-border-child {
    @apply py-[3px] px-[8px] bg-white rounded-full;
  }
}

.badges-backpacker {
  @apply backpacker backpacker-text;
}

.badges-voyager {
  @apply voyager voyager-text;
}

.badges-trailblazer {
  @apply trailblazer trailblazer-text;
}

.badges-expert-explorer {
  @apply expert-explorer expert-explorer-text;
}

.badges-adventurer {
  @apply adventurer adventurer-text;
}

.badges-rookie {
  @apply rookie rookie-text;
}

.badges-daytripper {
  @apply daytripper daytripper-text;
}

.badges-globetrotter {
  @apply globetrotter globetrotter-text;
}

input::-webkit-credentials-auto-fill-button {
  @apply invisible hidden pointer-events-none absolute w-0 right-0;
}

.str-p-paginator {
  .p-paginator {
    @apply flex gap-2;

    .p-paginator-pages {
      @apply flex gap-1;
      .p-paginator-page {
        @apply h-9 w-9 min-w-[36px] bg-transparent rounded-lg border-none hover:bg-palette-gray-100 text-palette-gray-500;
      }
      .p-paginator-page.p-highlight {
        @apply bg-palette-gray-100 text-palette-blue-600;
      }
    }

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      @apply h-9 w-9 min-w-[36px] bg-palette-base-white rounded-lg border border-palette-gray-300 hover:bg-palette-gray-100 text-palette-gray-500;
    }

    .p-paginator-current {
      @apply rounded-lg text-sm text-palette-gray-500 border-palette-gray-300 cursor-default;
    }
  }

  .p-link {
    @apply text-sm font-medium;
  }

  .p-inputtext {
    @apply min-h-0;
  }
}

.str-p-confirm-dialog {
  .p-dialog .p-dialog-header {
    @apply border-0 rounded-t-xl p-6 pb-2;
  }

  .p-dialog .p-dialog-content {
    @apply border-0 px-6 py-4;
  }

  .p-dialog .p-dialog-footer {
    @apply border-0 rounded-b-xl p-6 pt-2 flex w-full items-center justify-end gap-3;

    button {
      @apply m-0;
    }

    .t-cta-button {
      @apply h-11 min-w-fit px-5 py-1.5 flex gap-2 items-center justify-center rounded-lg border transition-colors text-sm font-semibold;
    }

    .t-reject {
      @apply border-palette-gray-300 text-palette-gray-900 bg-white hover:bg-palette-gray-200;
    }

    .t-accept-red {
      @apply border-palette-red-600 text-white bg-palette-red-600 hover:bg-palette-red-700;
    }

    .t-accept-blue {
      @apply border-palette-blue-600 text-white bg-palette-blue-600 hover:bg-palette-blue-700;
    }
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    @apply m-0;
  }
}

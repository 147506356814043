/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';
@import 'mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.app-container {
  @include container();
}
html {
  scrollbar-gutter: stable;
}

// Prevent horizontal scroll when user is on mobile device.
@media ((hover: none) and (pointer: coarse)) or (max-width: 768px) {
  html {
    overflow-x: hidden;
    width: 100%;
  }
}

body {
  width: 100%;
}

.emoji-mart-emoji-native {
  font-family: 'Apple Color Emoji', 'Noto Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.no-editor {
  .p-editor-toolbar.ql-toolbar.ql-snow.ng-star-inserted {
    display: none;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-left: 0px;
    border-right: 0px;
    border-width: 0px;
  }
  .ql-editor {
    padding: 0px 3px;
    font-size: 0.875rem;
    font-family: Inter, sans-serif;
  }
}

.str-custom-scrollbar {
  ::-webkit-scrollbar {
    @apply w-1.5 h-1.5 cursor-pointer transition-colors;
  }
  ::-webkit-scrollbar-track {
    @apply cursor-pointer rounded-full bg-transparent transition-colors hover:bg-palette-gray-200;
  }
  ::-webkit-scrollbar-thumb {
    @apply cursor-pointer rounded-full bg-palette-gray-300 transition-colors hover:bg-palette-gray-400;
  }
}

.str-required-marker {
  @apply after:content-['*'] after:ml-1 after:text-system-error-600;
}

input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button,
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@import 'styles/fonts';
@import 'styles/theme';

@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import 'styles/primeng-custom';
@import 'styles/svg';
@import '@soctrip-common/icon/soctrip-icon.css';
@import '~quill/dist/quill.snow.css';
@import '@ctrl/ngx-emoji-mart/picker';
@import 'prismjs/themes/prism.css';
@import 'nouislider/dist/nouislider.css';
